import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner-v2';
import Intro from './home-components/call-to-action-home';
import BlockHome from './home-components/block-home';
import BlockHomeTwo from './home-components/block-home-two';
import ServicesHome from './home-components/services-home';
import Directories from './home-components/directorios';
import Colors from './home-components/ColorPalette';
import Footer from './global-components/footer';
import ModalComponent from './home-components/modal-component';
import AboutPage from './section-components/about-v2';
import { ScrollTop } from './global-components/ScrollTop';
import Values from './section-components/values';
import Map from './contact-component/map';
import ColorPalette from './home-components/ColorPalette';

const Home_V2 = () => {
    return <div>
        <Navbar />
        <Banner />
        <Intro />
        <BlockHome />
        <Directories/>
        <BlockHomeTwo />
        <AboutPage bgimg="24"/>
        <Values/>
        <ServicesHome />
        <ColorPalette/>
        <Map/>
        <ScrollTop/>
        <ModalComponent/>
        <Footer />
    </div>
}

export default Home_V2

